import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
// import {Redirect} from 'react-router-dom';
import Tour from '../components/Tour/index';
import {
	ABOVE_THE_HEADER,
	BELOW_THE_HEADER,
	COLLAPSED_DRAWER,
	FIXED_DRAWER,
	HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import IntlMessages from 'util/IntlMessages';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import {hideModalError,showModalError} from 'actions/App';
import {getGlobalConfig} from 'actions/Setting';
import TopNav from 'components/TopNav';
import RestrictedRoute from 'containers/RestrictedRoute';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import {openModalLogin,closeModalLogin} from 'actions/App';
import {Dialog,DialogContent,DialogTitle,DialogContentText,DialogActions,Button,Slide,Typography} from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import 'moment/locale/es';
import HideOnScroll from '../components/HideOnScroll';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide
		direction="down"
		ref={ref}
		{...props}
	/>;
});

class App extends React.Component {

	constructor() {
		super();
		this.mainContainerRef = React.createRef();
	}

	state = {
		modalProveedor: false,
		mainContainer: null
	}

	componentDidMount=()=>{
		let url = this.props.location.pathname.split('/');
		//REDIRECCIONAR USUARIO NO PROVEEDOR
		if(this.props.authUser){
			if(this.props.authUser.invitado === 0){
				if(this.props.authUser.id_tipo_usuario !== 19 && localStorage.getItem("redirectProveedor")){
					this.setState({
						modalProveedor: true
					});
				}
			}else{
				this.props.openModalLogin();
			}
		}
		//VALIDAR URL & TIPO DE USUARIO PROVEEDOR
		if(url.length === 3){
			if(url[1]==="operaciones" && (parseInt(url[2], 10))>0){
				localStorage.setItem('redirectProveedor', this.props.location.pathname);
				if(!this.props.authUser){
					this.props.history.push('/signin', '_blank');
				}else if(this.props.authUser.id_tipo_usuario !== 19 && this.props.authUser.invitado === 0){
					this.setState({
						modalProveedor: true
					});
				}else if(this.props.authUser.invitado === 1){
					this.props.openModalLogin();
				}
			}
		}

		if(this.mainContainerRef) {
			this.setState({
				mainContainer: this.mainContainerRef.current
			})
		}
	}

	closeNotification = () => {
		this.setState({
			modalProveedor: false
		});
		localStorage.removeItem("redirectProveedor");
		this.props.history.push('/home', '_blank');
	}

	modalRedirectUserNoProveedor(){
		return(
			<Dialog
				open={this.state.modalProveedor}
				TransitionComponent={Transition}
				onClose={this.closeNotification}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<div style={{ width: '5em', margin: 'auto' }}>
						<WarningIcon style={{ color: '#ff5e00', fontSize: 70 }} />
					</div>

					<ThemeProvider theme={theme}>
						<Typography variant={'h4'} align={'center'} color={"primary"} style={{ textTransform: 'uppercase', margin: '10px 0 20px' }}>ACCESO NO AUTORIZADO</Typography>
					</ThemeProvider>

					<DialogContentText align={"center"}>
					<IntlMessages id='error.ErrorProveedor' />
					</DialogContentText>

				</DialogContent>

				<DialogActions className="justify-content-center">
					<Button onClick={() => this.closeNotification()} variant="contained" color="primary">
						CERRAR
					</Button>
				</DialogActions>

			</Dialog>
		);
	}

	componentWillMount=()=>{
		// if(this.props.logged){
			this.props.getGlobalConfig();
		// }
	}

	handleClose=()=>{
		this.props.hideModalError();
		if(this.props.urlError){
			this.props.history.push(this.props.urlError, '_blank');
		}
	}

	detail(){
		const {codeError} = this.props;
		return (
			<Dialog
				open={this.props.showError}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" style={{ backgroundColor: 'red', height: 50 }}>
					<IconButton aria-label="close" style={{ float: "right", marginLeft: '82%', marginTop: -7, position: 'absolute' }} onClick={this.handleClose}>
						<CloseIcon style={{ color: '#FFF' }} />
					</IconButton>
					<div align="center" style={{ color: '#FFF', marginTop: -8 }}>
						{codeError===500?<IntlMessages id='error.message.500'/>:null}
						{codeError===422?<IntlMessages id='error.message.422'/>:null}
					</div>
				</DialogTitle>
				<DialogContent style={{ backgroundColor: '' }}>
					<DialogContentText id="alert-dialog-description" style={{ color: 'black' }}>
						<IntlMessages id='error.message.content' className="content-error-mensaje" style={{ color: '#FFF' }} />
					</DialogContentText>
				</DialogContent>
			</Dialog>
		);
	}

	render() {
		const {match,drawerType,navigationStyle,horizontalNavPosition,pages,authUser,logged} = this.props;
		const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
		let page = process.env.REACT_APP_APP_ID;
		let url = this.props.location.pathname.split('/');

		if(url[1]==='user:admin'){
			localStorage.setItem('usuarioPruebaOPal', true);
		}
		if(page==='ZXC1V2C3V1' && !localStorage.getItem("usuarioPruebaOPal")){
			this.props.history.push("coming-soon");
		}
		//set default height and overflow for iOS mobile Safari 10+ support.
		if (isIOS && isMobile) {
			document.body.classList.add('ios-mobile-view-height')
		}
		else if (document.body.classList.contains('ios-mobile-view-height')) {
			document.body.classList.remove('ios-mobile-view-height')
		}
		window.recaptchaOptions = {
			lang: 'es',
			useRecaptchaNet: true,
			removeOnUnmount: false,
		};
		return (
			<div className={`app-container ${drawerStyle}`}>

				<Tour/>

				<Sidebar/>
				<div className="app-main-container">
					<HideOnScroll target={this.state.mainContainer}>
						<div className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
							{(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
								<TopNav styleName="app-top-header"/>}
							<Header/>
							{(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
								<TopNav/>}
						</div>
					</HideOnScroll>

					<main className="app-main-content-wrapper" ref={this.mainContainerRef}>
						<div className="app-main-content">

							{this.modalRedirectUserNoProveedor()}

							<div className="app-rapper">
								{this.props.showError && this.props.actionError==='modal'
									?this.detail():null
								}
								<Switch>
									{/* <Route path={`${match.path}booking/:tipo?/:idDestino?/:nombreDestino?/:fechaDesde?/:fechaHasta?`}
									component={asyncComponent(() => import('./routes/booking/Search'))} /> */}
									{
										this.props.authUser
										?
											this.props.authUser.id_tipo_usuario !== 19 &&
											<RestrictedRoute path={`${match.url}booking`} authUser={authUser} logged={logged} component={asyncComponent(() => import('./routes/booking/Search'))}/>
										:
										<RestrictedRoute path={`${match.url}booking`} authUser={authUser} logged={logged} component={asyncComponent(() => import('./routes/booking/Search'))}/>
									}
									{
										this.props.authUser
										?
											this.props.authUser.id_tipo_usuario !== 19 &&
											<RestrictedRoute path={`${match.url}hotels`} authUser={authUser} logged={logged} component={asyncComponent(() => import('./routes/booking/Hotel'))}/>
										:
										null
									}
									<RestrictedRoute path={`${match.url}checkout`} logged={logged} authUser={authUser} component={asyncComponent(() => import('./routes/booking/Checkout'))}/>
									<Route path={`${match.url}home`} logged={logged} authUser={authUser} component={asyncComponent(() => import('./routes/booking/Start'))}/>
									<Route path={`${match.url}mice`} logged={logged} authUser={authUser} component={asyncComponent(() => import('./routes/extraPages/routes/mice'))}/>
									{
										this.props.authUser
										?
											this.props.authUser.id_tipo_usuario !== 19 &&
											<RestrictedRoute path={[`${match.url}packages/:id`, `${match.url}packages`]} logged={logged} authUser={authUser} component={asyncComponent(() => import('./routes/extraPages/routes/destination'))}/>
										:
										<RestrictedRoute path={[`${match.url}packages/:id`, `${match.url}packages`]} logged={logged} authUser={authUser} component={asyncComponent(() => import('./routes/extraPages/routes/destination'))}/>

									}

									<Route path={`${match.path}page/all`} component={asyncComponent(()=> import('./routes/extraPages/routes/page'))} />
									<Route path={`${match.path}page/:nombre_interno`} component={asyncComponent(()=> import('./routes/extraPages/routes/page'))} />

									<Route path={`${match.path}blog/:id/:text`} logged={logged} component={asyncComponent(() => import('./routes/extraPages/routes/blog'))} />
									<Route pages={pages} page="blog" logged={logged} path={`${match.url}blog`} component={asyncComponent(() => import('./routes/extraPages/routes/blog'))}/>
									<Route pages={pages} page="contact-us" path={`${match.url}contact-us`} component={asyncComponent(() => import('./routes/extraPages/routes/contactUs'))}/>
									<Route pages={pages} page="work-with-us" path={`${match.url}work-with-us`} component={asyncComponent(() => import('./routes/extraPages/routes/workWithUs'))}/>
									<Route pages={pages} page="ipqrs" path={`${match.url}ipqrs`} component={asyncComponent(() => import('./routes/extraPages/routes/ipqrs'))}/>
									<Route pages={pages} page="hotel-confirm" path={`${match.url}acomodacion/confirmar/:hash`} component={asyncComponent(() => import('./routes/extraPages/routes/hotelConfirm'))}/>

									<RestrictedRoute path={`${match.url}profile/reserva/:reserva`} logged={logged} component={asyncComponent(() => import('./routes/socialApps/routes/PerfilReserva'))} />
									<RestrictedRoute path={`${match.url}profile`} logged={logged} component={asyncComponent(() => import('./routes/socialApps/routes/Profile'))} />
									{
										this.props.authUser
										?
											this.props.authUser.id_tipo_usuario !== 19 &&
											<RestrictedRoute path={`${match.url}reserva`} logged={logged} component={asyncComponent(() => import('./routes/socialApps/routes/Profile/reserva'))} />
										:
										null
									}
									{
										this.props.authUser
										?
											this.props.authUser.id_tipo_usuario === 19 &&
											<RestrictedRoute path={`${match.url}operaciones`} logged={logged} component={asyncComponent(() => import('./routes/socialApps/routes/Operaciones'))} />
										:
										null
									}
									<RestrictedRoute path={`${match.url}payment/:hash`} logged={logged} component={asyncComponent(() => import('./routes/extraPages/routes/paymentStatus'))}/>

									<Route component={asyncComponent(() => import('components/Error404'))}/>
								</Switch>

							</div>
						</div>
					{/* <Footer/> */}
					</main>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({auth, settings, app}) => ({
	authUser: auth.authUser,
	logged: auth.logged,

	drawerType: settings.drawerType,
	navigationStyle: settings.navigationStyle,
	horizontalNavPosition: settings.horizontalNavPosition,

	showError: app.showError,
	codeError: app.codeError,
	urlError: app.urlError,
	actionError: app.actionError
});


export default withRouter(connect(mapStateToProps,{
	getGlobalConfig,
	hideModalError,
	showModalError,
	openModalLogin,
	closeModalLogin,
})(App));