import React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import {loadAutoCompleteData} from 'actions/AutoComplete';
import {connect} from 'react-redux';
import makeAnimated from 'react-select/lib/animated';
import './app.css';

class AutoComplete extends React.Component {
	state = {
		selectedOption: null,
	}
	handleChange = (selectedOption) => {
		this.setState({ selectedOption });
	}

	render(){
		const { selectedOption } = this.state;
		const { buttonClean } = this.props;
		const error = this.props.error?{border: '1px solid red',borderRadius: 5}:{};

		const selectStyles = {
			container: (base, state) => ({
				...base,
				...error,
				opacity: state.isDisabled ? ".5" : "1",
				backgroundColor: "transparent",
				// zIndex: 1301,
			}),
			menuPortal: base => ({
				...base,
				// zIndex: 1301,
				minWidth:200,
			}),
			input: () => ({
				padding: 14,
				// marginLeft: -5
			}),
			singleValue: (provided, state) => {
				const opacity = state.isDisabled ? 0.5 : 1;
				const transition = 'opacity 300ms';
				return { ...provided, opacity, transition };
			},
			placeholder: (provided) => ({
					...provided,
					padding: 0,
					fontSize: "1rem",
					fontFamily: "Roboto, Helvetica, Arial, sans-serif",
					fontWeight: 400,
					lineHeight: 1,
					letterSpacing: "0.00938em",
			}),
			...this.props.extendStyles
		};
		return <div style={this.props.divStyle}>
			<AsyncSelect
				ref='select'
				isClearable={buttonClean}
				styles={selectStyles}
				onChange={this.handleChange}
				menuPlacement={"auto"}
				// selectProps={{
				// 	menuPlacement:"auto"
				// }}
				menuPortalTarget={this.props.target || document.body}
				components={makeAnimated()}
				loadOptions={(value,callback)=>{
					this.props.queryData[this.props.queryParam || "query"] = value;
					this.props.loadAutoCompleteData(this.props.queryData,this.props.url,this.props.id,callback);
				}}
				defaultOptions={true}
				selectedOption={selectedOption}
				{...this.props}
			/>
		</div>;
	}
}
const mapStateToProps = ({AutoComplete,auth}) => {
	const {logged} = auth;
	return {logged,AutoComplete}
};

export default connect(mapStateToProps, {
	loadAutoCompleteData
})(AutoComplete);