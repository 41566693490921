import React from 'react';
import IntlMessages from 'util/IntlMessages';
import {Typography, Tooltip} from '@material-ui/core';
import './app.css';

const CartItem = ({item}) => {

	// hotel
	if(item.tipo === 'acomodacion'){
		const {img_url,nombre,precio,nombre_acomodacion/*,noches*/,form} = item;
		const {/*adultos,menores,habitaciones,*/divisa} = form;

		let valor_item = parseFloat(precio).toLocaleString('en-EN', { style: 'currency', currency: divisa, currencyDisplay:'code', minimumFractionDigits: 0 });

		return (
		<li style={{borderBottom:'solid 1px #dee2e6'}}>
			<div className="media align-items-center">
				<div className="mr-3">
					<img alt="..." src={img_url} className="jr-user-wid object-cover rounded-lg"/>
				</div>
				<div className="media-body py-3 pr-3">
					<Typography variant="subtitle2" gutterBottom noWrap>{nombre}</Typography>
					<Tooltip title={nombre_acomodacion} aria-label="Title">
						<Typography variant="body2" gutterBottom noWrap>{nombre_acomodacion}</Typography>
					</Tooltip>
					<Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.adultos'/>: {form.adultos}</Typography>
					<p className="mb-0 text-success ">{valor_item}</p>
				</div>
			</div>
		</li>
		);
	}else{
		const {url_portada, nombre_servicio, price, moneda} = item;
		var valor_item = parseFloat(price.total_venta).toLocaleString('en-EN', { style: 'currency', currency: moneda, currencyDisplay:'code', minimumFractionDigits: 0 });

		return (
		<li style={{ marginTop: -5 }}>
			<div className="media align-items-center">
				<div className="mr-3">
					<img alt="..." src={url_portada} className="jr-user-wid object-cover rounded-lg"/>
				</div>
				<div className="media-body py-3 pr-3">
					<Tooltip title={nombre_servicio} aria-label="Title">
						<Typography className="textItemCartMiniItem" variant="subtitle2" gutterBottom noWrap>{nombre_servicio}</Typography>
					</Tooltip>
					<Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.adultos'/>: {item.form.adultos}</Typography>
					<p className="mb-0 text-success ">{valor_item}</p>
				</div>
			</div>
		</li>
		);
	}
};

export default CartItem;
