import {
	BOOKING_ADD_TO_CART,
	BOOKING_REMOVE_FROM_CART,
	BOOKING_SHOW_CART,
	BOOKING_HIDE_CART,
	BOOKING_TOGGLE_CART,
	BOOKING_SEARCH_LOAD,
	BOOKING_SEARCH_LOADED,
	PUNTO_CARTA_LOAD,
	LOAD_SERVICIO_DETAIL,
	SERVICIO_DETAIL_LOADED,
	UPDATE_SEARCH_PARAMS,
	UPDATE_SEARCH_PARAMS_HOTEL,
	CLEAR_SEARCH,
	RESERV_CONF_OF_SERVICE_QUERY,
	RESERV_CONF_OF_SERVICE_DATA,
	RESERV_CONF_OF_SERVICE_CLEAR,
	RESERV_PRICE_QUERY,
	RESERV_PRICE_DATA,
	RESERV_PRICE_CLEAR,
	BOOKING_CLEAR_CART,
	BOOKING_LOCK_DROPDOWN_CART,
	RESERV_CHECKOUT_SAVE,
	RESERV_CHECKOUT_RESPONSE,
	RESERV_CHECKOUT_CLEAR,
	RESERV_EDIT_MODE_ON,
	RESERV_EDIT_MODE_OFF,
	BOOKING_SEARCH_HOTEL_LOAD,
	BOOKING_SEARCH_HOTEL_LOADED,
	CLEAR_HOTEL_SEARCH,
	HOTEL_ACOMODACION_REQUEST,
	HOTEL_ACOMODACION_RESPONSE,
	HOTEL_ZONAS_REQUEST,
	HOTEL_ZONAS_RESPONSE,
	HOTEL_PRICE_ACOMODACION_REQUEST,
	HOTEL_PRICE_ACOMODACION_RESPONSE,
	ERASE_HOTELES_CART,
	CHANGE_ORIGEN,
	HOTEL_DISPONIBILIDAD_REQUEST,
	HOTEL_DISPONIBILIDAD_RESPONSE,
	HOTEL_DISPONIBILIDADES_REQUEST,
	HOTEL_DISPONIBILIDADES_RESPONSE,
	SEARCH_DESTINATION_POINT
} from 'constants/ActionTypes';

export const addToCart = (data) => {
	return {
		type: BOOKING_ADD_TO_CART,
		payload: data
	};
};

export const removeFromCart = (data) => {
	return {
		type: BOOKING_REMOVE_FROM_CART,
		payload: data
	};
};

export const clearCart = () => {
	return {
		type: BOOKING_CLEAR_CART,
	};
};

export const showCart = () => {
	return {
		type: BOOKING_SHOW_CART
	};
};

export const lockDropdownCart = (value) => {
	return {
		type: BOOKING_LOCK_DROPDOWN_CART,
		value: value
	};
};

export const hideCart = () => {
	return {
		type: BOOKING_HIDE_CART
	};
};

export const toggleCart = () => {
	return {
		type: BOOKING_TOGGLE_CART
	};
};

export const searchData = (data) => {
	return {
		type: BOOKING_SEARCH_LOAD,
		payload: data
	};
};

export const searchHotelData = (data) => {
	return {
		type: BOOKING_SEARCH_HOTEL_LOAD,
		payload: data
	};
};

export const dataSearched = (data) => {
	return {
		type: BOOKING_SEARCH_LOADED,
		payload: data
	};
};

export const puntoSearched = (data) => {
	return {
		type: PUNTO_CARTA_LOAD,
		payload: data
	}
}

export const dataHotelSearched = (data) => {
	return {
		type: BOOKING_SEARCH_HOTEL_LOADED,
		payload: data
	};
};

export const loadServicioDetail = (data) => {
	return {
		type: LOAD_SERVICIO_DETAIL,
		payload: data
	};
};

export const servicioDetailLoaded = (data) => {
	return {
		type: SERVICIO_DETAIL_LOADED,
		payload: data.data
	};
};

export const updateSearchParams = (data) => {
	return {
		type: UPDATE_SEARCH_PARAMS,
		payload: data
	};
};

export const updateSearchParamsHotel = (data) => {
	return {
		type: UPDATE_SEARCH_PARAMS_HOTEL,
		payload: data,
	};
};

export const eraseHotelesCart = () => {
	return {
		type: ERASE_HOTELES_CART,
	};
};

export const clearSearch = () => {
	return {
		type: CLEAR_SEARCH,
	};
};

export const clearHotelSearch = () => {
	return {
		type: CLEAR_HOTEL_SEARCH,
	};
};

export const getConfService = (data) => {
	return {
		type: RESERV_CONF_OF_SERVICE_QUERY,
		payload: data,
	};
};

export const setConfService = (data) => {
	return {
		type: RESERV_CONF_OF_SERVICE_DATA,
		payload: data
	};
};


export const clearConfService = () => {
	return {
		type: RESERV_CONF_OF_SERVICE_CLEAR,
	};
};

export const getPrice = (data) => {
	return {
		type: RESERV_PRICE_QUERY,
		payload: data
	};
};

export const setPrice = (data) => {
	return {
		type: RESERV_PRICE_DATA,
		payload: data
	};
};

export const clearPrice = () => {
	return {
		type: RESERV_PRICE_CLEAR,
	};
};

export const saveCheckout = (payload)=>{
	return {
		type: RESERV_CHECKOUT_SAVE,
		payload: payload
	}
}

export const responseSaveCheckout = (payload)=>{
	return {
		type: RESERV_CHECKOUT_RESPONSE,
		payload: payload
	}
}
export const clearResponseCheckout = (payload)=>{
	return {
		type: RESERV_CHECKOUT_CLEAR,
		payload: payload
	}
}
export const editModeOn = (payload)=>{
	return {
		type: RESERV_EDIT_MODE_ON,
		payload: payload
	}
}
export const editModeOff = ()=>{
	return {
		type: RESERV_EDIT_MODE_OFF
	}
}

export const acomodacionRequest = (payload) => {
	return {
		type: HOTEL_ACOMODACION_REQUEST,
		payload: payload,
	};
};

export const acomodacionResponse = (payload) => {
	return {
		type: HOTEL_ACOMODACION_RESPONSE,
		payload: payload,
	};
};

export const zonasRequest = (payload) => {
	return {
		type: HOTEL_ZONAS_REQUEST,
		payload: payload,
	};
};

export const zonasResponse = (payload) => {
	return {
		type: HOTEL_ZONAS_RESPONSE,
		payload: payload,
	};
};

export const priceAcomodacionRequest = (payload, callback) => {
	return {
		type: HOTEL_PRICE_ACOMODACION_REQUEST,
		payload: payload,
		callback
	};
};

export const priceAcomodacionResponse = (payload) => {
	return {
		type: HOTEL_PRICE_ACOMODACION_RESPONSE,
		payload: payload,
	};
};

export const changeOrigen = (payload) => {
	return {
		type: CHANGE_ORIGEN,
		payload: payload,
	};
};

export const hotelDisponibilidadRequest = (payload, callback) => {
	return {
		type: HOTEL_DISPONIBILIDAD_REQUEST,
		payload: payload,
		callback
	};
};

export const hotelDisponibilidadResponse = (payload) => {
	return {
		type: HOTEL_DISPONIBILIDAD_RESPONSE,
		payload: payload,
	};
};

export const hotelMultipleDisponibilidadesRequest = (payload, callback) => {
	return {
		type: HOTEL_DISPONIBILIDADES_REQUEST,
		payload: payload,
		callback
	};
};

export const hotelMultipleDisponibilidadesResponse = (payload) => {
	return {
		type: HOTEL_DISPONIBILIDADES_RESPONSE,
		payload: payload,
	};
};

export const searchDestinationPoint = (payload,callback) => {
	return {
		type: SEARCH_DESTINATION_POINT,
		payload,
		callback
	};
};