import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import BackgroundComponent from 'containers/BackgroundComponent';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import AutoComplete from 'components/AutoComplete';

import InputAdornment from '@material-ui/core/InputAdornment';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import {
	hideMessage,
	showAuthLoader,
	userSignUp,
	sendSignUpData
} from 'actions/Auth';
import Alert from '../components/Alert';

const initialState = {
	email:'',
	password:'',
	tipo_persona:1,
	nombre:'',
	apellidos:'',
	phone:'',
	agencia:'',
	nit:'',
	direccion:'',
	pais:'',
	ciudad:'',
	password_check:'',
	tipo_nit:0,
	showPassword: false,
	step:1,
	checkNotification:true,
	defaultOptionsCiudad: false,
	ciudadValid: true,
	paisValid: true
};
class SignUp extends React.Component {
	constructor() {
		super();
		this.state = initialState;
		this.states = {
			email:'fvalenciabg@gmail.com',
			password:'prueba',
			tipo_persona:1,
			nombre:'Fredy',
			apellidos:'Valencia',
			phone:'3508249979',
			agencia:'Begranda',
			nit:'15428685',
			direccion:'CR 70 74 16',
			pais:'Colombia',
			ciudad:'Medellin',
			password_check:'prueba',
			tipo_nit:5,
			showPassword: false,
			step:1,
		}
	}

	regexNit=(value, caso)=>{
		var result = true;
		var regex = null;
		switch (caso) {
			case 5:
				regex = new RegExp(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/);
				result = regex.test(value);
			break;
			case 2:
				regex = new RegExp(/^[1-9]\d{4,9}$/);
				result = regex.test(value);
			break;
			case 3:
				regex = new RegExp(/^[a-zA-Z]*[1-9]\d{3,7}$/);
				result = regex.test(value);
			break;
			// case 1:
			// 	regex = new RegExp(/^\d{2}[0-1][0-9][0-3][0-9]?\d{5}$/);
			// 	result = regex.test(value);
			// break;
			case 6:
				regex = new RegExp(/^\w{4,12}$/);
				result = regex.test(value);
			break;
			default:
				result = true;
		}
		return result;
	}

	handleChangeCheckbox=(event)=>{
		this.setState({
			checkNotification: event.target.checked
		})
	}

	regexName = (value) => {
		var regex = new RegExp(/^[a-zA-ZñÑáéíóú_]*((\s)*[a-zA-ZñÑáéíóú_]*)$/);
		var result = regex.test(value);
		return result;
	}

	componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
		});
		ValidatorForm.addValidationRule('validNit', (value) => {
			return this.regexNit(value, this.state.tipo_nit);
		});
		ValidatorForm.addValidationRule('validName', (value) => {
			return this.regexName(value);
		})
    }

	componentWillUnmount() {
        // ValidatorForm.removeValidationRule('isPasswordMatch', '');
    }

	componentDidUpdate() {
		// if (this.props.showMessage) {
		//	 setTimeout(() => {
		//		 this.props.hideMessage();
		//	 }, 3000);
		// }
		if (this.props.logged && (this.props.authUser && !this.props.authUser.invitado)) {
			this.props.history.push('/');
		}
	}

	handlePaisCiudadChange = (value, field) => {
		this.setState({
			[field.name]: value.item.nombre,
			[`${field.name}Valid`]: !!value.item.nombre
		})

		if(field.name === 'pais') {
			this.setState({
				defaultOptionsCiudad: true,
				ciudad: ''
			})
		}
	}

	handleChange = event => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	}
	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	}
	handleSubmit = ()=>{
		this.setState((state) => {return {...state, ciudadValid: this.state.ciudad, paisValid: this.state.pais }});

		if (!this.state.paisValid || !this.state.ciudadValid) return;

		this.props.showAuthLoader();
		this.props.sendSignUpData({
			email:this.state.email,
			password:this.state.password,
			tipo_solicitud:this.state.tipo_persona,
			nombres:this.state.nombre,
			apellidos:this.state.apellidos,
			telefono:this.state.phone,
			agencia:this.state.agencia,
			nit:this.state.nit,
			direccion:this.state.direccion,
			pais:this.state.pais,
			idioma:'es',
			ciudad:this.state.ciudad,
			password_check:this.state.password_check,
			tipo_nit:this.state.tipo_nit,
			showPassword:this.state.showPassword,
			notification:this.state.checkNotification
		},()=>{
			this.setState(initialState);
		});
	}
	handleClose = () => {
		this.props.hideMessage();
	};
	handleCloseConfirm = () => {
		this.props.hideMessage();
		window.location.href = '/home';
	};

	render() {
		const {
			email,
			password,
			tipo_persona,
			nombre,
			apellidos,
			phone,
			agencia,
			nit,
			direccion,
			pais,
			ciudad,
			password_check,
			step,
			defaultOptionsCiudad,
			ciudadValid,
			paisValid
		} = this.state;
		const {loader, alertMessage, alertType, showMessage, hideMessage} = this.props;
		return (
		<BackgroundComponent>
			{showMessage && <Alert hideMessage={hideMessage} type={alertType} title="notification.info" content={alertMessage}/> }

			<div style={{height:'100vh'}} className="app-login-container app-register-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
				<div className="app-login-main-content">
					<div className="app-logo-content d-flex align-items-center justify-content-center">
						<Link className="logo-lg" to="/" title="Jambo">
							<img src={process.env.REACT_APP_LOGO || require("assets/images/logo.png")} alt="jambo" title="jambo"/>
						</Link>
					</div>

					<div className="app-login-content">

						<div className="mb-4">
							<h2>
								{
									this.state.tipo_persona===1 && process.env.REACT_APP_APP_ID==='DZVTWIOOVB' && step===2?
									<IntlMessages id="signIn.SignUpAgencia"/>:
									<IntlMessages id="appModule.createAccount"/>
								}
							</h2>
						</div>

						<div className="app-login-form">
							<ValidatorForm onSubmit={this.handleSubmit}>
								{step===1?
									<div>
										<FormControl margin={"normal"} className="col-12">
											<InputLabel shrink htmlFor="tipo_persona-placeholder"><IntlMessages id="app.tipoUsuario"/></InputLabel>
											<br/>
											<TextField
												select
												name={"tipo_persona"}
												value={this.state.tipo_persona}
												variant="outlined"
												onChange={this.handleChange}
												required
												input={<Input name="tipo_persona" id="tipo_persona-placeholder" />}
											>
												<MenuItem disabled value="">Seleccionar</MenuItem>
												<MenuItem value={0}>Persona Natural</MenuItem>
												<MenuItem value={1}>Agencia o Empresa</MenuItem>
											</TextField>
										</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<TextValidator
												type="text"
												onChange={this.handleChange}
												variant="outlined"
												required
												style={{marginRight:6}}
												validators={['required', 'validName']}
												name="nombre"
												value={nombre}
												label={<IntlMessages id="app.nombres"/>}
											/>
										</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<TextValidator
												type="text"
												onChange={this.handleChange}
												required
												variant="outlined"
												validators={['required', 'validName']}
												errorMessages={[<IntlMessages id="app.validations.required"/>]}
												name="apellidos"
												value={apellidos}
												label={<IntlMessages id="app.apellidos"/>}
											/>
											</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<TextValidator
												type="email"
												onChange={this.handleChange}
												style={{marginRight:6}}
												required
												variant="outlined"
												validators={['required','isEmail']}
												errorMessages={[<IntlMessages id="app.validations.required"/>,'Introduce']}
												name="email"
												value={email}
												label={<IntlMessages id="app.email"/>}
											/>
										</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<TextValidator
												type="phone"
												onChange={this.handleChange}
												required
												variant="outlined"
												validators={['matchRegexp:^[0-9]*$']}
												errorMessages={['Solo numeros']}
												name="phone"
												value={phone}
												label={<IntlMessages id="app.telefono"/>}
											/>
										</FormControl>
									</div>
								:
									<div>
										<FormControl margin={"normal"} className="col-12">
											<TextValidator
												type="text"
												hidden={tipo_persona !== 1}
												onChange={this.handleChange}
												variant="outlined"
												required={tipo_persona === 1}
												validators={tipo_persona !== 1?[]:['required']}
												errorMessages={[<IntlMessages id="app.validations.required"/>]}
												name="agencia"
												value={agencia}
												label={<IntlMessages id="app.agencia"/>}
											/>
										</FormControl>

										<FormControl margin={"normal"} className="col-12">
											<InputLabel shrink htmlFor="tipo_noit-placeholder">{<IntlMessages id="app.tipoDocumento"/>} </InputLabel>
											<br/>
											<TextField
												select
												variant="outlined"
												name={"tipo_nit"}
												value={this.state.tipo_nit}
												onChange={this.handleChange}
												input={<Input name="tipo_nit" id="tipo_noit-placeholder" />}
											>
												{
													tipo_persona === 0?
														[
															[0,'Registro Cívil'],
															// [1,'Tarjeta de identidad'],
															[2,'Cédula'],
															[3,'Tarjeta extranjera'],
															[4,'Cedula extranjera'],
															[4,'Tarjeta de Extranjería']
														].map((v,i)=>(<MenuItem grp="persona-options" key={i} value={v[0]}>{v[1]}</MenuItem>))
													:
														[
															[5,'Nit'],
															[6,'Pasaporte'],
															[8,'Sin Identificación ó para uso de la DIAN'],
															[9,'Activo Fijo'],
															[5,'DNI'],
															[5,'RUT'],
															[5,'RUC'],
															[5,'RFC'],
															[5,'RUN'],
															[5,'RIU'],
															[5,'Número de identificación Fiscal o tributaria de la agencia'],
														].map((v,i)=>(<MenuItem grp="persona-options" key={i} value={v[0]}>{v[1]}</MenuItem>))
												}
											</TextField>
										</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<TextValidator
												type="text"
												required
												validators={['required', 'validNit']}
												errorMessages={[<IntlMessages id="app.validations.required"/>, 'Nit invalido']}
												onChange={this.handleChange}
												variant="outlined"
												style={{marginRight:6}}
												id="nit"
												name="nit"
												value={nit}
												label={<IntlMessages id="app.identificacion"/>}
											/>
										</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<TextValidator
												type="text"
												onChange={this.handleChange}
												variant="outlined"
												required
												validators={['required']}
												errorMessages={[<IntlMessages id="app.validations.required"/>]}
												name="direccion"
												value={direccion}
												label={<IntlMessages id="app.direccion"/>}
											/>
										</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<AutoComplete
												id={"pais"}
												queryData={{}}
												url="site/paises"
												className={"mr-2"}
												variant="outlined"
												name="pais"
												placeholder={<IntlMessages id="app.pais"/>}
												onChange={this.handlePaisCiudadChange}
												error={!paisValid && <IntlMessages id="app.validations.required"/>}
												value={pais && [{value: pais, label: pais}]}
											/>
										</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<AutoComplete
												id={"ciudad"}
												className={"mr-2"}
												url={"site/ciudades"}
												queryData={{pais: pais}}
												key={"ciudad-de-" + pais}
												variant="outlined"
												name="ciudad"
												defaultOptions={defaultOptionsCiudad}
												placeholder={<IntlMessages id="app.ciudad"/>}
												onChange={this.handlePaisCiudadChange}
												error={!ciudadValid && <IntlMessages id="app.validations.required"/>}
												value={ciudad && [{value: ciudad, label: ciudad}]}
											/>
										</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<TextValidator
												required
												validators={['required']}
												errorMessages={[<IntlMessages id="app.validations.required"/>]}
												variant="outlined"
												type={this.state.showPassword ? 'text' : 'password'}
												style={{marginRight:6}}

												onChange={this.handleChange}
												name="password"
												autoComplete="new-password"
												value={password}
												label={<IntlMessages id="app.pass"/>}
												InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="Toggle password visibility"
															onClick={this.handleClickShowPassword}
														>
															{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												),
												}}
											/>
										</FormControl>
										<FormControl margin={"normal"} className="col-6">
											<TextValidator
												type={this.state.showPassword ? 'text' : 'password'}
												validators={['required','isPasswordMatch']}
												errorMessages={[<IntlMessages id="app.validations.required"/>, 'La contraseña no coincide']}
												variant="outlined"
												onChange={this.handleChange}
												name="password_check"
												value={password_check}
												label={<IntlMessages id="app.verificarPass"/>}
												InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="Toggle password visibility"
															onClick={this.handleClickShowPassword}
														>
															{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												),
												}}
											/>
										</FormControl>

									</div>

								}
								<div className="col-12" align="center">
									{step!==1?
										<FormControlLabel
											className="checkbox-register"
											control={
												<Checkbox
													checked={this.state.checkNotification}
													onChange={this.handleChangeCheckbox}
													value="checkedB"
													color="primary"
													style={{ fontSize: 5 }}
												/>
											}
											label={<IntlMessages id="app.checkMsmNotification" style={{ fontSize: 5 }} />}
										/>
										:
										null
									}

								</div>
								<div className="mb-3 d-flex align-items-center justify-content-between">
									{step===1?
										<Button color="primary" onClick={()=>{this.setState({step:2});}}>
											<IntlMessages id="appModule.next"/>
										</Button>
									:
										<span>
											<Button color="primary" onClick={()=>{this.setState({step:1});}}>
												<IntlMessages id="appModule.back"/>
											</Button>
											<Button type="submit" variant="contained" color="primary">
												<IntlMessages id="appModule.regsiter"/>
											</Button>
										</span>
									}
									<Link to="/signin">
										<IntlMessages id="signUp.alreadyMember"/>
									</Link>
								</div>
							</ValidatorForm>
						</div>
					</div>

				</div>

				{
				loader &&
				<div className="loader-view">
					<CircularProgress/>
				</div>
				}
				<NotificationContainer/>
			</div>
		</BackgroundComponent>
		)
	}
}

const mapStateToProps = ({auth}) => {
	const {loader, alertMessage, showMessage, authUser,alertType} = auth;
	return {loader, alertMessage, showMessage, authUser,alertType}
};

export default connect(mapStateToProps, {
	userSignUp,
	hideMessage,
	showAuthLoader,
	sendSignUpData
})(SignUp);
