import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from 'util/IntlMessages';
export default class Confirm extends React.Component {
  state = {
    open: true,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (btn) => {
    this.props.hideConfirm(btn);
    this.setState({ open: false });
  };

  render() {
    const {content,title} = this.props;
    return (
      <div>
        <Dialog

          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          
          <DialogTitle id="form-dialog-title"><IntlMessages id={title}/></DialogTitle>
          <DialogContent><DialogContentText><IntlMessages id={content}/></DialogContentText></DialogContent>

          <DialogActions>
            <Button onClick={()=>{this.handleClose(false);}} color="secondary">
                <IntlMessages id='button.no'/>
            </Button>
            <Button onClick={()=>{this.handleClose(true);}} color="primary" autoFocus>
                <IntlMessages id='button.yes'/>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
