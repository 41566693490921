import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import blogSagas from './BlogSagas';
import AutoCompleteSagas from './AutoCompleteSagas';
import MiceSagas from './MiceSagas';
import SettingsSagas from './SettingsSagas';
import ContactSagas from './ContactSagas';
import WorkWithUsSagas from './WorkWithUsSagas';
import IpqrsSagas from "./IpqrsSagas";
import WidgetsSagas from './WidgetsSagas';
import BookingSagas from './BookingSagas';
import DestinationSagas from './DestinationSagas';
import PagesSagas from './PagesSagas';
import ProfileSagas from './ProfileSagas';
import OperationsSagas from './OperationsSagas';
import paymentStatus from './PaymentStatusSagas';
import HotelConfirm from './HotelConfirmSagas';

export default function* rootSaga(getState) {
	yield all([
		authSagas(),
		blogSagas(),
		AutoCompleteSagas(),
		MiceSagas(),
		SettingsSagas(),
		ContactSagas(),
		WorkWithUsSagas(),
		IpqrsSagas(),
		WidgetsSagas(),
		BookingSagas(),
		DestinationSagas(),
		PagesSagas(),
		ProfileSagas(),
		OperationsSagas(),
		paymentStatus(),
		HotelConfirm()
	]);
}
