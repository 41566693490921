import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import {removeFromCart,lockDropdownCart} from 'actions/Booking';
import Confirm from 'components/Confirm';
import { Grid, Tooltip } from '@material-ui/core';
import {NO_IMAGE_SRC} from 'constants/Fallback';

class CartItem extends React.Component {

	state = {
		modalVisible: false,
	};

	showConfirm = ()=>{
		this.props.lockDropdownCart(true);
		this.setState({modalVisible: true}, ()=>{});

	}

	hideConfirm = (btn)=>{
		this.setState({modalVisible: false}, ()=>{this.props.lockDropdownCart(false);});
		if(btn===true){
			this.doRemove();
		}
	}

	doRemove = () => {
		this.props.removeFromCart(this.props.item.uid);
	}

	render (){
		// hotel
		if(this.props.item.tipo === 'acomodacion'){
			const {img_url,nombre,precio,nombre_acomodacion,noches,form} = this.props.item;
			const {adultos,menores,divisa,cantidad,fecha_ini,fecha_fin} = form;
			let valor_item = parseFloat(precio).toLocaleString('en-EN', { style: 'currency', currency: divisa?divisa:'USD', currencyDisplay:'code', minimumFractionDigits: 0 });
			return (
				<li className="media">
					{this.state.modalVisible && <Confirm {...this.props} hideConfirm={this.hideConfirm} title={'app.confirm'} content={'app.confirmTextDeleteFromCart'} />}
					<Grid container>
						<Grid item xs={2} className='user-avatar mb-5' style={{paddingRight: '16px'}}>
							{/* <img alt={nombre} src={img_url} className="rounded-lg"/> */}
							<img
								width="100%"
								className="rounded-lg"
								src={img_url || NO_IMAGE_SRC}
								style={{display: this.state.imageStatus === 'loaded'?'none':'block'}}
								alt={nombre}
								onLoad={this.handleImageLoaded}
								onError={(e) => {
									e.target.src= NO_IMAGE_SRC;
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle2" gutterBottom noWrap>{nombre}</Typography>
							<Tooltip title={nombre} aria-label="Title">
								<Typography variant="body2" gutterBottom noWrap>{nombre_acomodacion}</Typography>
							</Tooltip>
							{/* <Typography variant="body1" gutterBottom className='text-muted'>{descripcion_servicio}</Typography> */}
							<Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.noches'/>: {noches}</Typography>
							<Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.adultos'/>: {adultos}</Typography>
							<Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.fecha'/>: {fecha_ini} - {fecha_fin}</Typography>
							{!!menores && <Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.ninos'/>: {menores}</Typography>}
							{!!cantidad && <Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.habitaciones'/>: {cantidad}</Typography>}
						</Grid>
						<Grid item xs={3} style={{textAlign: 'center'}}>
							<Typography variant="subtitle2" gutterBottom className='text-success'>{valor_item}</Typography>
						</Grid>
						<Grid item xs={1} style={{textAlign: 'center', marginTop: '-14px'}}>
							<IconButton aria-label="Delete" onClick={this.showConfirm}><DeleteIcon/></IconButton>
						</Grid>
					</Grid>
				</li>
			);

		// servicios
		}else{
			const {url_portada, nombre_servicio, price, moneda, form, simbolo_divisa, trm_divisa, fecha_ini} = this.props.item;
			// let valor_item = parseFloat(price.total_venta).toLocaleString('en-EN', { style: 'currency', currency: moneda, currencyDisplay:'code', minimumFractionDigits: 0 });
			let valor_item = parseFloat(simbolo_divisa?Math.ceil(price.total_venta / trm_divisa):price.total_venta).toLocaleString('en-EN', { style: 'currency', currency: simbolo_divisa || moneda, currencyDisplay:'code', minimumFractionDigits: 0 });

			return (
				<li className="media">
					{this.state.modalVisible && <Confirm {...this.props} hideConfirm={this.hideConfirm} title={'app.confirm'} content={'app.confirmTextDeleteFromCart'} />}
					<Grid container>
						<Grid item xs={2} className='user-avatar mb-5' style={{paddingRight: '16px'}}>
							{/* <img alt={nombre_servicio} src={url_portada} className="rounded-lg"/> */}
							<img
								width="100%"
								className="rounded-lg"
								src={url_portada || NO_IMAGE_SRC}
								// style={{display: this.state.imageStatus === 'loaded'?'block':'none'}}
								alt={nombre_servicio}
								onLoad={this.handleImageLoaded}
								onError={(e) => {
									e.target.src= NO_IMAGE_SRC;
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<Tooltip title={nombre_servicio} aria-label="Title">
								<Typography variant="subtitle2" gutterBottom noWrap>{nombre_servicio}</Typography>
							</Tooltip>
							{/* <Typography variant="body1" gutterBottom className='text-muted'>{descripcion_servicio}</Typography> */}
							<Typography></Typography>
							<Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.adultos'/>: {form.adultos}</Typography>
							<Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.fecha'/>: {fecha_ini} </Typography>
							{!!form.menores && <Typography variant="body1" gutterBottom noWrap><IntlMessages id='app.ninos'/>: {form.menores}</Typography>}
						</Grid>
						<Grid item xs={3} style={{textAlign: 'center'}}>
							<Typography variant="subtitle2" gutterBottom className='text-success'>{valor_item}</Typography>
						</Grid>
						<Grid item xs={1} style={{textAlign: 'center', marginTop: '-14px'}}>
							<IconButton aria-label="Delete" onClick={this.showConfirm}><DeleteIcon/></IconButton>
						</Grid>
					</Grid>
				</li>
			);
		}
	};
}

const mapStateToProps = state => ({
	// cart: state.booking.cart,
});

export default connect(mapStateToProps, {
	removeFromCart, lockDropdownCart
})(CartItem);
