import React from 'react';

import CartItem from './CartItem';
import CartMiniItem from './CartMiniItem';
import IntlMessages from 'util/IntlMessages';
import { Link } from 'react-router-dom'
import CustomScrollbars from 'util/CustomScrollbars';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {connect} from 'react-redux';

class CartList extends React.Component {

	render(){
		const {mini} = this.props;
		//CREAR ARRAY CON CADA CIUDAD
		let arrayOne = this.props.cart;
		let arrayTwo = [];
		arrayOne.forEach(function(element_one) {
			if(arrayTwo.length===0){
				arrayTwo.push({id_destino: element_one.id_destino, nombre_destino: element_one.nombre_destino});
			}else{
				var create = true;
				arrayTwo.forEach(function(element_two) {
					if(element_two.id_destino === element_one.id_destino){
						create = false;
						return;
					}
				});
				if(create){
					arrayTwo.push({id_destino: element_one.id_destino, nombre_destino: element_one.nombre_destino});
				}
			}
		});
		if(mini){
			return (
				<ul className="list-unstyled" style={{maxWidth:'100%',overflow:'hidden',textOverflow:'ellipsis'}}>
					{
						arrayTwo.map((item2, index) =>
							<div key={index}>
								<h6 style={{ fontSize: '0.875rem', fontWeight: 500, borderBottom:'solid 1px #dee2e6' }}>{item2.nombre_destino} </h6>
								{arrayOne.filter(obj => obj.id_destino === item2.id_destino).map((item, index) => <CartMiniItem key={index} item={item}/>) }
								<br/>
							</div>
						)
					}
				</ul>
				// <ul className="list-unstyled" style={{maxWidth:'100%',overflow:'hidden',textOverflow:'ellipsis'}}>
				// 	{this.props.cart.map((item, index) => <CartMiniItem key={index} item={item}/>)}
				// </ul>
			);
		}
		return (
			<div>
				{
					this.props.cart.length > 0 ?
					<CustomScrollbars className="messages-list scrollbar" style={{minHeight: 280,height:'30vh'}}>
						<ul className="list-unstyled">
							{this.props.cart.map((item, index) => <CartItem key={index} item={item}/>)}
						</ul>
					</CustomScrollbars>
					:
					<div>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>
							<ShoppingCartIcon style={{ fontSize: 70 }} />
						</div>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 23 }}>
							<IntlMessages id='app.emptyCart'/>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
						<Link to="/booking" className="btn btn-primary" style={{ backgroundColor: '#3f52b5', borderColor: '#3f52b5' }}><IntlMessages id='app.agregarCarrito'/></Link>
						</div>
					</div>
				}
			</div>
		)
}
};

const mapStateToProps = state => ({
	cart: state.booking.cart
});

export default connect(mapStateToProps, {})(CartList);