import './app.css';
import React from 'react';
import {connect} from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import ReCAPTCHA from "react-google-recaptcha";
import AutoComplete from 'components/AutoComplete';
import Alert from 'components/Alert';
import LoadingOverlay from 'react-loading-overlay';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
	Link,
	Input,
	Dialog,
	Button,
	Checkbox,
	MenuItem,
	TextField,
	IconButton,
	InputLabel,
	FormControl,
	DialogTitle,
	DialogContent,
	InputAdornment,
	LinearProgress,
	FormControlLabel,
	CircularProgress,
	DialogContentText,
} from '@material-ui/core';
//ACTIONS
import {
	userSignIn,
	showAuthLoader,
	sendRecovery,
	hideMessage,
	hideRecoveryMessage,
	sendSignUpDirectData,
	showAuthMessage
} from 'actions/Auth';

class Login extends React.Component {

	captcha = null;
	dialogRef = null;

	state = {
		nit: '',
		email: '',
		phone: '',
		pais: '',
		ciudad: '',
		nombre: '',
		tipo_nit: 5,
		password: '',
		captcha: null,
		apellidos: '',
		activeStep: 0,
		registerStep: 1,
		openModal: false,
		password_check: '',
		showPassword: false,
		buttonRegister: true,
		ciudad_label: 'Ciudad',
		checkNotification: true,
	};

	componentDidMount() {
		localStorage.removeItem("changeTipoViaje");
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
		});
		ValidatorForm.addValidationRule('validNit', (value) => {
			return this.regexNit(value, this.state.tipo_nit);
		});
		ValidatorForm.addValidationRule('validName', (value) => {
			return this.regexName(value);
		})
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.alertMessage) {
			this.captcha.reset();
		}
	}

	handleCloseAlert = () => {
		this.props.hideMessage();
	};

	regexNit=(value, caso)=>{
		var result = true;
		var regex = null;
		switch (caso) {
			case 5:
				regex = new RegExp(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/);
				result = regex.test(value);
			break;
			case 2:
				regex = new RegExp(/^[1-9]\d{4,9}$/);
				result = regex.test(value);
			break;
			case 3:
				regex = new RegExp(/^[a-zA-Z]*[1-9]\d{3,7}$/);
				result = regex.test(value);
			break;
			// case 1:
			// 	regex = new RegExp(/^\d{2}[0-1][0-9][0-3][0-9]?\d{5}$/);
			// 	result = regex.test(value);
			// break;
			case 6:
				regex = new RegExp(/^\w{4,12}$/);
				result = regex.test(value);
			break;
			default:
				result = true;
		}
		return result;
	}

	regexName = (value) => {
		var regex = new RegExp(/^[a-zA-ZñÑáéíóú_]*((\s)*[a-zA-ZñÑáéíóú_]*)$/);
		var result = regex.test(value);
		return result;
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	}

	// handleChangeCity=(item)=>{
	// 	console.log('item label: ',item.label);
	// 	console.log('item value: ',item.value);
	// 	this.setState({
	// 		ciudad: item.value,
	// 		ciudad_label: item.label
	// 	});
	// }

	paisField = () => {
		const {pais} = this.state;
		let value = "";
		if(pais) {
			value = [{value: this.state.pais, label: this.state.pais}]
		}
		return (<AutoComplete
			id={"pais"}
			queryData={{}}
			url="site/paises"
			className={"mr-2"}
			variant="outlined"
			name="pais"
			target={this.dialogRef}
			placeholder={<IntlMessages id="app.pais"/>}
			onChange={(value,field)=>{this.handlePaisCiudadChange(value,field)}}
			error={this.state.errorSelect}
			value={value}
		/>)
	}

	ciudadField = () => {
		const {ciudad} = this.state;
		let value = "";
		if(ciudad) {
			value = [{value: this.state.ciudad, label: this.state.ciudad}]
		}
		return (<AutoComplete
			id={"ciudad"}
			className={"mr-2"}
			url={"site/ciudades"}
			queryData={{ipqrs: true, pais: this.state.pais}}
			key={"ciudad-de-" + this.state.pais}
			variant="outlined"
			name="ciudad"
			target={this.dialogRef}
			defaultOptions={this.state.defaultOptions}
			placeholder={<IntlMessages id="app.ciudad"/>}
			onChange={(value,field)=>{this.handlePaisCiudadChange(value,field)}}
			error={this.state.errorSelect}
			value={value}
		/>)
	}

	handlePaisCiudadChange = (value, field) => {
		this.setState({
			[field.name]: value.item.nombre,
		})

		if(field.name === 'pais') {
			this.setState({
				defaultOptions: true,
				ciudad: ''
			})
		}
	}

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	}

	handleClose=()=>{
		this.setState({
			openModal: false
		});
	}

	handleSubmit = ()=>{
		this.props.showAuthLoader();
		this.props.sendSignUpDirectData({
			nombres:this.state.nombre,
			apellidos:this.state.apellidos,
			email:this.state.email,
			tipo_nit:this.state.tipo_nit,
			nit:this.state.nit,
			tipo_solicitud:0,
			ciudad:this.state.ciudad,
			pais:this.state.pais,
			telefono:this.state.phone,
			// agencia:this.state.agencia,
			// direccion:this.state.direccion,
			idioma:'es',
			// password_check:this.state.password_check,
			// showPassword:this.state.showPassword,
			password:this.state.password,
			notification:this.state.checkNotification,
			captcha:this.state.captcha
		});
	}
	handleChangeCheckbox=(event)=>{
		this.setState({
			checkNotification: event.target.checked
		})
	}
	//CASE 0
	getLogin(){
		const {
			email,
			password,
		} = this.state;
		const {loader} = this.props;
		return(
			<div>
				<DialogTitle id="alert-dialog-title"><IntlMessages id="appModule.signin"/></DialogTitle>
				<DialogContent style={{ marginTop: -15 }}>
					<div className="app-login-form">
						<ValidatorForm
							ref="form"
							onSubmit={() => {
								this.props.showAuthLoader();
								this.props.userSignIn({email, password},function(){

								});
							}}
						>
							<fieldset>
								<TextValidator
									label={<IntlMessages id="appModule.email"/>}
									fullWidth
									onChange={(event) => this.setState({email: event.target.value})}
									value={email}
									margin="normal"
									variant={'outlined'}
									className="mt-1 my-sm-3"
									validators={['required','isEmail']}
									errorMessages={[<IntlMessages id="app.camporequerido" />,<IntlMessages id="app.emaillinvalido" />]}
								/>
								<TextValidator
									type="password"
									label={<IntlMessages id="appModule.password"/>}
									fullWidth
									onChange={(event) => this.setState({password: event.target.value})}
									value={password}
									variant={'outlined'}
									margin="normal"
									className="mt-1 my-sm-3"
									validators={['required']}
									errorMessages={[<IntlMessages id="app.camporequerido" />]}
								/>
								{
									!loader &&
									<div>
										<div className="mb-3 d-flex align-items-center justify-content-between">
											<Button
												variant="contained"
												type="submit"
												color="primary"
											>
												<IntlMessages id="appModule.signIn"/>
											</Button>
											<div>
												<Link onClick={()=>this.setState({activeStep:1})} className="link-auth">
													<IntlMessages id="appModule.signup"/>
												</Link>
											</div>
										</div>
										<div className="app-social-block my-1 my-sm-3">
											<Link onClick={()=>this.setState({activeStep:2})} className="link-auth">
												<IntlMessages id="signIn.forgotpassword"/>
											</Link>
										</div>
									</div>
								}
							</fieldset>
						</ValidatorForm>
					</div>
				</DialogContent>
			</div>
		);
	}
	//CASE 1
	getRegister(){
		const {
			nit,
			// pais,
			phone,
			email,
			nombre,
			password,
			apellidos,
			registerStep,
			password_check,
		} = this.state;
		// const key_prod = "6LdZJtIUAAAAAKRMYItHHGQh2S-tl-kE30IRHbzi";
		const key_prod = process.env.REACT_APP_RECAPTCHA; // key de pruebas
		return(
			<div>
				<DialogTitle id="alert-dialog-title"><IntlMessages id={"signIn.signUp"}/></DialogTitle>
				<DialogContent style={{ marginTop: -15 }}>
					<div className="app-login-form">
						<ValidatorForm onSubmit={this.handleSubmit}>
							{
								registerStep===1
								?
								<div>
									<FormControl margin={"normal"} className="col-6">
										<TextValidator
											type="text"
											onChange={this.handleChange}
											variant="outlined"
											required
											style={{marginRight:6}}
											validators={['required', 'validName']}
											name="nombre"
											value={nombre}
											label={<IntlMessages id="app.nombres"/>}
										/>
									</FormControl>
									<FormControl margin={"normal"} className="col-6">
										<TextValidator
											type="text"
											onChange={this.handleChange}
											required
											variant="outlined"
											validators={['required', 'validName']}
											errorMessages={[<IntlMessages id="app.validations.required"/>]}
											name="apellidos"
											value={apellidos}
											label={<IntlMessages id="app.apellidos"/>}
										/>
									</FormControl>
									<FormControl className="col-lg-6 col-md-12" margin={"normal"} component="fieldset" >
										{this.paisField()}
									</FormControl>
									<FormControl className="col-lg-6 col-md-12" margin={"normal"} component="fieldset" >
										{this.ciudadField()}
									</FormControl>
									{/* <FormControl margin={"normal"} className="col-6">
										<AutoComplete
											style={{ width: 192 }}
											id={"site_ciudades"}
											queryData={{}}
											url="site/ciudades"
											variant="outlined"
											name="ciudad"
											// cleanAction={console.log('cleanAction')}
											buttonClean={false}
											placeholder={<IntlMessages id="app.ciudad"/>}
											onChange={(ciudad,field)=>{this.handleChangeCity(ciudad,field)}}
											value={[{value: ciudad, label: ciudad_label}]}
										/>
										<TextValidator
											type="text"
											required
											style={{marginRight:6}}
											variant="outlined"
											validators={['required']}
											errorMessages={[<IntlMessages id="app.validations.required"/>]}
											onChange={this.handleChange}
											name="ciudad"
											value={ciudad}
											label={<IntlMessages id="app.ciudad"/>}
										/>
									</FormControl> */}
									<FormControl margin={"normal"} className="col-6">
										<TextValidator
											type="phone"
											onChange={this.handleChange}
											variant="outlined"
											validators={['matchRegexp:^[0-9]*$']}
											errorMessages={[<IntlMessages id="app.validations.required"/>,'Solo numeros']}
											name="phone"
											value={phone}
											label={<IntlMessages id="app.telefono"/>}
										/>
									</FormControl>
								</div>
								:
								<div>
									<FormControl margin={"normal"} className="col-12">
										<InputLabel shrink htmlFor="tipo_noit-placeholder">{<IntlMessages id="app.tipoDocumento"/>} </InputLabel>
										<br/>
										<TextField
											select
											variant="outlined"
											name={"tipo_nit"}
											value={this.state.tipo_nit}
											onChange={this.handleChange}
											input={<Input name="tipo_nit" id="tipo_noit-placeholder" />}
										>
											{
												true?
													[
														[0,'Registro Cívil'],
														[2,'Cédula'],
														[3,'Tarjeta de extranjería'],
														[4,'Cedula extranjera'],
														[6,'pasaporte']
													].map((v,i)=>(<MenuItem grp="persona-options" key={i} value={v[0]}>{v[1]}</MenuItem>))
												:null
											}
										</TextField>
									</FormControl>
									<FormControl margin={"normal"} className="col-6">
										<TextValidator
											type="text"
											required
											validators={['required', 'validNit']}
											errorMessages={[<IntlMessages id="app.validations.required"/>, 'Nit invalido']}
											onChange={this.handleChange}
											variant="outlined"
											style={{marginRight:6}}
											id="nit"
											name="nit"
											value={nit}
											label={<IntlMessages id="app.identificacion"/>}
										/>
									</FormControl>
									<FormControl margin={"normal"} className="col-6">
										<TextValidator
											type="email"
											onChange={this.handleChange}
											style={{marginRight:6}}
											required
											variant="outlined"
											validators={['required','isEmail']}
											errorMessages={[<IntlMessages id="app.validations.required"/>,'Introduce']}
											name="email"
											value={email}
											label={<IntlMessages id="app.email"/>}
										/>
									</FormControl>
									<FormControl margin={"normal"} className="col-6">
										<TextValidator
											required
											validators={['required']}
											errorMessages={[<IntlMessages id="app.validations.required"/>]}
											variant="outlined"
											type={this.state.showPassword ? 'text' : 'password'}
											style={{marginRight:6}}

											onChange={this.handleChange}
											name="password"
											value={password}
											label={<IntlMessages id="app.pass"/>}
											InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="Toggle password visibility"
														onClick={this.handleClickShowPassword}
													>
														{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											),
											}}
										/>
									</FormControl>
									<FormControl margin={"normal"} className="col-6">
										<TextValidator
											type={this.state.showPassword ? 'text' : 'password'}
											validators={['required','isPasswordMatch']}
											errorMessages={[<IntlMessages id="app.validations.required"/>, 'La contraseña no coincide']}
											variant="outlined"
											onChange={this.handleChange}
											name="password_check"
											value={password_check}
											label={<IntlMessages id="app.verificarPass"/>}
											InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="Toggle password visibility"
														onClick={this.handleClickShowPassword}
													>
														{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											),
											}}
										/>
									</FormControl>
									<div className="col-12" align="center">
										{registerStep!==1?
											<FormControlLabel
												className="checkbox-register"
												control={
													<Checkbox
														checked={this.state.checkNotification}
														onChange={this.handleChangeCheckbox}
														value="checkedB"
														color="primary"
														style={{ fontSize: 5 }}
													/>
												}
												label={<IntlMessages id="app.checkMsmNotification" style={{ fontSize: 5 }} />}
											/>
											:
											null
										}
									</div>
									<div className="col-12">
										<ReCAPTCHA
										ref={el => { this.captcha = el}}
										style={{
											display: "inline-block",
											transform:'scale(0.77)',
											webkitTransform:'scale(0.77)',
											transformOrigin:'0 0',
											webkitTransformOrigin:'0 0'
										}}
										sitekey={key_prod}
										onChange={(token) => {
											this.setState({
												captcha: token,
												buttonRegister: false,
											})
										}}
										/>
									</div>
								</div>
							}
							<div className="mb-3 d-flex align-items-center justify-content-between">
								{registerStep===1?
									<Button color="primary" onClick={()=>{this.setState({registerStep:2});}}>
										<IntlMessages id="appModule.next"/>
									</Button>
								:
									<span>
										<Button color="primary" onClick={()=>{this.setState({registerStep:1});}}>
											<IntlMessages id="appModule.back"/>
										</Button>
										<Button type="submit" variant="contained" color="primary" disabled={this.state.buttonRegister}>
											<IntlMessages id="appModule.regsiter"/>
										</Button>
									</span>
								}
								<Link onClick={()=>this.setState({activeStep:0})} className="link-auth">
									<IntlMessages id="signUp.alreadyMember"/>
								</Link>
							</div>
						</ValidatorForm>
					</div>
				</DialogContent>
			</div>
		);
	}
	//CASE 2
	getRecuperarPass(){
		const {
			email
		} = this.state;
		const {alertMessage, showPasswordMessage, loader} = this.props;
		return(
			<div>
				<DialogTitle id="alert-dialog-title"><IntlMessages id="app.passwordRecovery"/></DialogTitle>
				<DialogContent style={{ marginTop: -15 }}>
					<div className="app-login-form">
						<ValidatorForm
							ref="form"
							onSubmit={() => {
								this.props.sendRecovery({email:email});
							}}
						>
							{
								showPasswordMessage?
									<DialogContentText dangerouslySetInnerHTML={{ __html: alertMessage }}></DialogContentText>
								:
								<TextValidator
									label={<IntlMessages id="appModule.email"/>}
									fullWidth
									onChange={(event) => this.setState({email: event.target.value})}
									value={email}
									margin="normal"
									variant={"outlined"}
									name="email"
									className="mt-1 my-sm-3"
									validators={['required', 'isEmail']}
									errorMessages={[<IntlMessages id="app.camporequerido" />,<IntlMessages id="app.emaillinvalido" />]}
								></TextValidator>
							}
							<div className="mb-3 d-flex align-items-center justify-content-between">
								<Button
									style={{ fontSize: 12 }}
									variant="contained"
									type="submit"
									color="primary"
									hidden={showPasswordMessage}
									disabled={loader}
								>
									<IntlMessages id="app.sendRecovery"/>
								</Button>
								{
									!loader &&
									<div>
										<Link className="link-auth" onClick={() => {
											this.setState({activeStep:0})
											this.props.hideRecoveryMessage();
										}}>
											<IntlMessages id="app.back"/>
										</Link>
									</div>
								}
							</div>
						</ValidatorForm>
						{
							loader &&
							<LinearProgress />
						}
					</div>
				</DialogContent>
			</div>
		);
	}

	getStepContent = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				return this.getLogin();
			case 1:
				return this.getRegister();
			case 2:
				return this.getRecuperarPass();
			default:
				return 'Uknown stepIndex';
		}
	}

	render() {
		const {
			activeStep,
		} = this.state;

		const { showMessage, alertMessage, alertType, hideMessage } = this.props;

		return(

			<Dialog
				fullWidth
				open={this.props.showLogin}
				maxWidth={'xs'}
				onClose={this.props.hideLogin}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				ref={el => this.dialogRef = el}
			>
				{showMessage && <Alert hideMessage={hideMessage} type={alertType} title="notification.info" content={alertMessage}/> }
				<LoadingOverlay
					active={this.props.loader}
					spinner={<CircularProgress className='align-middle mr-2' size={30} />}
					text={<IntlMessages id='app.loading' />}
					fadeSpeed={500}
					styles={{
						overlay: (base) => ({
							...base,
							background: 'rgba(255, 255, 255, 0.9)',
							borderRadius: 8
						}),
						content:(base) => ({
							...base,
							color:'#303f9f',
						}),
					}}
				>
					{this.getStepContent(activeStep)}
				</LoadingOverlay>
			</Dialog>

		);
	}
}

const mapStateToProps = state => ({
	showMessage: state.auth.showMessage,
	loader: state.auth.loader,
	showPasswordMessage: state.auth.showPasswordMessage,
	alertMessage: state.auth.alertMessage,
	currency: state.settings.currency,
	currencies: state.settings.currencies,
});

export default connect(mapStateToProps, {
	userSignIn,
	sendSignUpDirectData,
	showAuthLoader,
	sendRecovery,
	hideMessage,
	hideRecoveryMessage,
	showAuthMessage
})(Login);